<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            class="twobarsRight"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
            data-v-6a52651e=""
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
              data-v-6a52651e=""
            ></path>
          </svg>
          <p class="sidebar-heading">4D Graph</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5 pt-3">
          <div class="flex flex-col mt-3">
            <p class="label_heading">X - Axis</p>
            <!-- ["lstk", "hklx", "tqx", "drtm"] -->
            <select
              :id="widgetId + '2'"
              class="h-9 border-0 block w-5/12 fields font-color"
              @change="mnemonicChangeHandler($event, 1)"
              v-model="tag2"
            >
              <!-- <option>
                {{ tag2 }}
              </option> -->
              <option
                :key="index"
                :value="tag"
                v-for="(tag, index) in logs?.displayTags"
              >
                {{ tag }}
              </option>
            </select>
            <a
              class="drop_clear_btn_"
              v-if="
                this.$store.state.rect.rects[this.idx].mnemonicTag2.indexOf('DEFAULT') == -1 &&
                isExpandFeatureTwo
              "
              @click="
                this.tag2 = '';
                mnemonicRemoveHandler('DEFAULT1', 1);
              "
              >X</a
            >
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Y - Axis</p>

            <select
              :id="widgetId + '2'"
              class="h-9 border-0 block w-5/12 fields font-color"
              @change="mnemonicChangeHandler($event, 2)"
              v-model="tag3"
            >
              <!-- <option>
                {{ tag3 }}
              </option> -->
              <option
                :key="index"
                :value="tag"
                v-for="(tag, index) in logs?.displayTags"
              >
                {{ tag }}
              </option>
              <!-- <option value="saab">Saab</option>
                  <option value="fiat">Fiat</option>
                <option value="audi">Audi</option>-->
            </select>
            <a
              class="drop_clear_btn_"
              v-if="
                this.$store.state.rect.rects[this.idx].mnemonicTag3.indexOf('DEFAULT') == -1 &&
                isExpandFeatureTwo
              "
              @click="mnemonicRemoveHandler('DEFAULT2', 2)"
              >X</a
            >
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Z - Axis</p>

            <select
              :id="widgetId + '2'"
              class="h-9 border-0 block w-5/12 fields font-color"
              @change="mnemonicChangeHandler($event, 0)"
              v-model="tag1"
            >
              <!-- <option>
                {{ tag1 }}
              </option> -->
              <option
                :key="index"
                :value="tag"
                v-for="(tag, index) in logs?.displayTags"
              >
                {{ tag }}
              </option>
              <!-- <option value="saab">Saab</option>
                  <option value="fiat">Fiat</option>
                <option value="audi">Audi</option>-->
            </select>
            <p
              class="drop_clear_btn_"
              v-if="
                this.$store.state.rect.rects[this.idx].mnemonicTag1.indexOf('DEFAULT') == -1 &&
                isExpandFeatureTwo
              "
              @click="mnemonicRemoveHandler('DEFAULT0', 0)"
            >
              X
            </p>
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Value Axis</p>

            <select
              :id="widgetId + '2'"
              class="h-9 border-0 block w-5/12 fields font-color"
              @change="mnemonicChangeHandler($event, 3)"
              v-model="tag4"
            >
              <!-- <option>
                {{ tag4 }}
              </option> -->
              <option
                :key="index"
                :value="tag"
                v-for="(tag, index) in logs?.displayTags"
              >
                {{ tag }}
              </option>
              <!-- <option value="saab">Saab</option>
                  <option value="fiat">Fiat</option>
                <option value="audi">Audi</option>-->
            </select>
            <a
              class="drop_clear_btn_"
              v-if="
                this.$store.state.rect.rects[this.idx].mnemonicTag4.indexOf('DEFAULT') == -1 &&
                isExpandFeatureTwo
              "
              @click="mnemonicRemoveHandler('DEFAULT3', 3)"
              >X</a
            >
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Grid Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeColor($event)"
              :value="gridColor"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeBackgroundColor($event)"
              :value="backgroundColor"
            />
          </div>
        </div>
      </div>
    </div>

    <UpDownButtons
      :isExpandFeatureSix="isExpandFeatureSix"
      :toggle_height_handler_six="toggle_height_handler_six"
      :id="id"
      :displayId="properties.displayId"
      :widgetId="properties.widgetId"
      :closePropertyFile="closeNavigation"
    />
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import { useWellLogs } from "../../composable/useWellLogs";

export default {
  name: "3d-Graph-Properties",
  components: {
    UpDownButtons,
  },
  props: {
    displayId: String,
    widgetId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: false,
      isExpandFeatureTwo: true,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureSix: false,

      tag4: "",
      Dtags: [],
      logs: null,
      idx: null,
    };
  },
  computed: {
    gridColor(){
      return this.$store.state.rect.rects[this.idx].gridColor || "#ffffff";
    },
    backgroundColor(){
      return this.$store.state.rect.rects[this.idx].backgroundColor || "#c8c8e6";
    },
    tag1() {
      if(this.$store.state.rect.rects[this.idx].mnemonicTag1 != "DEFAULT0"){
        return this.logs?.displayTags[
          this.logs?.tags.indexOf(
            this.$store.state.rect.rects[this.idx].mnemonicTag1
          )
        ];
      }
      return null;
      // return this.logs?.displayTags[
      //   this.logs?.tags.indexOf(
      //     this.properties.mnemonicTag1
      //   )
      // ];
    },
    tag2() {
      if(this.$store.state.rect.rects[this.idx].mnemonicTag2 != "DEFAULT1"){
        return this.logs?.displayTags[
          this.logs?.tags.indexOf(
            this.$store.state.rect.rects[this.idx].mnemonicTag2
          )
        ];
      }
      return null;
    },
    tag3() {
      if(this.$store.state.rect.rects[this.idx].mnemonicTag3 != "DEFAULT2"){
        return this.logs?.displayTags[
          this.logs?.tags.indexOf(
            this.$store.state.rect.rects[this.idx].mnemonicTag3
          )
        ];
      }
      return null;
      // return this.logs?.displayTags[
      //   this.logs?.tags.indexOf(
      //     this.properties.mnemonicTag3
      //   )
      // ];
    },
    tag4() {
      if(this.$store.state.rect.rects[this.idx].mnemonicTag4 != "DEFAULT3"){
        return this.logs?.displayTags[
          this.logs?.tags.indexOf(
            this.$store.state.rect.rects[this.idx].mnemonicTag4
          )
        ];
      }
      return null;
      // return this.logs?.displayTags[
      //   this.logs?.tags.indexOf(
      //     this.properties.mnemonicTag4
      //   )
      // ];
    },

    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
    changeColor(e) {
      this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
        field: 'gridColor'
      });
    },
    changeBackgroundColor(e) {
      this.$store.dispatch("rect/changeBackgroundColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    async mnemonicRemoveHandler(e, index) {
      this.$store.dispatch("rect/plotlyMnemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e,
        index,
      });
    },
    async mnemonicChangeHandler(e, index) {
      this.$store.dispatch("rect/plotlyMnemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: this.logs?.tags[this.logs?.displayTags.indexOf(e.target.value)],
        index,
      });
      // let tagValue = await api.WellServices.getDataForTags({
      //   fields: e.target.value
      // });

      // alert(JSON.stringify(tagValue.data.rows[0][e.target.value]))
      // this.$store.dispatch("rect/numericValueUpdate", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   value: tagValue.data.rows[0][e.target.value]
      // });
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
  },
  beforeMount(){
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      let foundrect = rects.findIndex(rect=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundrect >= 0){
        this.idx = foundrect;
      }
      else{
        this.idx = null;
      }
    }
  },
  async mounted() {
    this.logs = await this.getLogs(
      this.$store.state.disp.displays[this.displayId].wellId,
      "depth"
    );
  },
  setup() {
    const { getLogs } = useWellLogs();
    return {
      getLogs,
    };
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
